import React, { useState, useEffect, useRef } from 'react';
import './Main.css';
import ImageSlider from './ImageSlider';
import SubscriptionForm from './SubscriptionForm';
import { useScroll } from './ScrollContext';
import { useModal } from './ModalContext';
import IosLaunchModal from './IosLaunchModal';
import EventModal from './EventModal';

export default function Main() {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const { addRef } = useScroll();

  useEffect(() => {
    addRef('section1', section1Ref);
    addRef('section2', section2Ref);
    addRef('section3', section3Ref);
    addRef('section4', section4Ref);
    addRef('section5', section5Ref);
  }, [addRef]);

  const userGuideImages = [
    './images/mockup/1.png',
    './images/mockup/2.png',
    './images/mockup/3.png',
    './images/mockup/4.png',
  ];

  const supplierGuideImages = [
    './images/mockup/5.png',
    './images/mockup/6.png',
    './images/mockup/7.png',
    './images/mockup/8.png',
  ];

  const userText = [
    ['주식 실계좌를 연동한 투자자의', '수익률을 확인하세요'],
    ['높은 수익률을 기록하고 마음에 드는', '투자자의 포트폴리오를 구독하세요'],
    ['보유주식을 카피하고', '나만의 포트폴리오를 만드세요'],
    ['누구나 쉽게,', '주식투자 수익을 낼 수 있어요'],
  ];

  const supplierText = [
    ['주식 실계좌를 연동하고', '공급자로 신청하세요'],
    ['나만의 제안서를 작성하고', '가격을 설정합니다'],
    ['구독자가 생긴다면', '수익이 창출돼요'],
    ['수익률이 높다면', '더 많은 구독자가 생길거에요'],
  ];

  const [currentUserSlide, setCurrentUserSlide] = useState(0);

  const [currentSupplierSlide, setCurrentSupplierSlide] = useState(0);

  const handleUserSlide = (index) => {
    setCurrentUserSlide(index);
  };

  const handleSupplierSlide = (index) => {
    setCurrentSupplierSlide(index);
  };

  const { isIosModalOpen, showIosModal, hideIosModal } = useModal();

  const [showEventModal, setShowEventModal] = useState(false);

  useEffect(() => {
    const hidePopup = localStorage.getItem('hidePopup');
    if (hidePopup !== new Date().toDateString()) {
      setShowEventModal(true);
    }
  }, []);

  return (
    <>
      {showEventModal && (
        <EventModal onClose={() => setShowEventModal(false)} />
      )}
      <div className='main-container'>
        {/* 메인이미지 */}
        <div className='main-background-container'>
          <div className='main-title-container'>
            <div className='main-title-top'>
              주식투자의 <span className='main-title-br'>새로운 문화</span>
            </div>
            <div className='main-title-bottom'>리틀버핏</div>
            <div className='download-container'>
              {/*<div onClick={showIosModal} className="ios-link">
                <img className='download-icon' src="./images/appstore.png" alt="apple download icon" />
              </div>*/}
              <a
                href='https://apps.apple.com/kr/app/id6477274306'
                target='_blank'
                rel='noopener noreferrer'
                className='android-link'
              >
                <img
                  className='download-icon'
                  src='./images/appstore.png'
                  alt='apple download icon'
                />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.fractalfn.littlebuffett'
                target='_blank'
                rel='noopener noreferrer'
                className='android-link'
              >
                <img
                  className='download-icon'
                  src='./images/googleplay.png'
                  alt='google download icon'
                />
              </a>
            </div>
          </div>
        </div>
        {/* 비전 */}
        <div ref={section1Ref} className='vision-container'>
          <div className='vision-index'>Vision</div>
          <div className='vision-title'>
            누구나 쉽게, 주식투자 수익을 낼 수 있어야 한다
          </div>
          <div className='vision-text'>
            처음 시작하는 주식투자를 어려워하지 않고 누구나 쉽게,{' '}
            <span className='vision-text-span'>
              수익률을 높일 수 있는 책임형 투자 문화를 만드는 것이 우리의 최종
              목표입니다.
            </span>
          </div>
          <div className='vision-image-container'>
            <div className='vision-image-text'>함께 만들어가요.</div>
            <div className='vision-image-text'>주식투자의 새로운 문화</div>
          </div>
        </div>
        {/* 사업 소개 */}
        <div className='business-background'>
          <div className='business-container'>
            <div className='business-index'>Our Business</div>
            <div className='business-section-one'>
              <div className='business-content-container'>
                <div className='business-title'>리틀버핏 플랫폼</div>
                <div className='business-text'>
                  <b>다른 사람들과 비교하면 내 수익률은 높은걸까?</b>
                  <br />
                  <br />
                  리틀버핏 플랫폼은 내 실계좌를 연동하여 매일 내 수익률을
                  확인하고, 다른 사람들과 비교하여 투자 실력을 겨룰 수 있습니다.
                  <br />
                  <br />
                  주식을 처음 접하는 사람도 투자 전문가들을 따라해 쉽게 수익을
                  낼 수 있도록 돕습니다. <br />
                  매일 업데이트 되는 투자 전문가의 수익률을 체크하고, 마음에
                  든다면 구독해주세요. <br />
                  보유 주식을 확인하고 내 포트폴리오에 적용해 높은 수익률을
                  달성하는 것이 서비스의 목표입니다.
                </div>
              </div>
              <div className='business-image'>
                <img src='./images/3.png' alt='our business ' />
              </div>
            </div>
            <div className='business-section-two'>
              <div className='business-image'>
                <img src='./images/4.png' alt='our business ' />
              </div>
              <div className='business-content-container'>
                <div className='business-title'>
                  토큰증권(STO) 플랫폼
                  <span className='business-title-wip'> (개발 중)</span>
                </div>
                <div className='business-text'>
                  투자 전문가의 포트폴리오를 토큰증권 형식으로 만들어 실시간으로
                  투자자와 전문가의 자산을 연동시켜 거래할 수 있는 서비스입니다.
                  <br />
                  <br /> 자신이 운용하는 실계좌로 토큰증권을 발행하고 이를
                  따라갈 수 있는 직관적인 구조로 책임형 투자 문화를 형성하고
                  대중화하는 것이 서비스의 목표입니다.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 사용법 */}
        <div ref={section2Ref} className='use-container'>
          <div className='use-index'>How to Use</div>
          <div className='use-section'>
            <div className='use-title'>
              구독: 다른 투자자는 어떤 종목에 투자할까? 궁금하지 않나요?
            </div>
            <div className='use-content-container'>
              <div className='use-image-slider'>
                <ImageSlider
                  images={userGuideImages}
                  afterChange={handleUserSlide}
                  currentSlide={currentUserSlide}
                  text={userText}
                />
              </div>
              <div className='verticalDottedLine'></div>
              <div className='use-list-container'>
                <div className='use-content-section'>
                  <div
                    className={`use-stage ${
                      currentUserSlide === 0 ? 'active' : ''
                    }`}
                    onClick={() => handleUserSlide(0)}
                  >
                    1
                  </div>
                  <div className='use-text'>
                    주식 실계좌를 연동한 투자자의 <br />
                    수익률을 확인하세요
                  </div>
                </div>
                <div className='use-content-section'>
                  <div
                    className={`use-stage ${
                      currentUserSlide === 1 ? 'active' : ''
                    }`}
                    onClick={() => handleUserSlide(1)}
                  >
                    2
                  </div>
                  <div className='use-text'>
                    높은 수익률을 기록하고 마음에 드는 <br />
                    투자자의 포트폴리오를 구독하세요
                  </div>
                </div>
                <div className='use-content-section'>
                  <div
                    className={`use-stage ${
                      currentUserSlide === 2 ? 'active' : ''
                    }`}
                    onClick={() => handleUserSlide(2)}
                  >
                    3
                  </div>
                  <div className='use-text'>
                    보유주식을 카피하고
                    <br />
                    나만의 포트폴리오를 만드세요
                  </div>
                </div>
                <div className='use-content-section'>
                  <div
                    className={`use-stage ${
                      currentUserSlide === 3 ? 'active' : ''
                    }`}
                    onClick={() => handleUserSlide(3)}
                  >
                    4
                  </div>
                  <div className='use-text'>
                    누구나 쉽게,
                    <br />
                    주식투자 수익을 낼 수 있어요
                  </div>
                </div>
              </div>
            </div>
            <div className='space-container' />
          </div>
          <div className='use-section'>
            <div className='use-title'>
              크리에이터: 내 계좌도 자랑할 수 있어요!
            </div>
            <div className='use-content-container'>
              <div className='use-image-slider'>
                <ImageSlider
                  images={supplierGuideImages}
                  afterChange={handleSupplierSlide}
                  currentSlide={currentSupplierSlide}
                  text={supplierText}
                />
              </div>
              <div className='verticalDottedLine'></div>
              <div className='use-list-container'>
                <div className='use-content-section'>
                  <div
                    className={`use-stage ${
                      currentSupplierSlide === 0 ? 'active' : ''
                    }`}
                    onClick={() => handleSupplierSlide(0)}
                  >
                    1
                  </div>
                  <div className='use-text'>
                    주식 실계좌를 연동하고
                    <br />
                    공급자로 신청하세요
                  </div>
                </div>
                <div className='use-content-section'>
                  <div
                    className={`use-stage ${
                      currentSupplierSlide === 1 ? 'active' : ''
                    }`}
                    onClick={() => handleSupplierSlide(1)}
                  >
                    2
                  </div>
                  <div className='use-text'>
                    나만의 제안서를 작성하고
                    <br />
                    가격을 설정합니다
                  </div>
                </div>
                <div className='use-content-section'>
                  <div
                    className={`use-stage ${
                      currentSupplierSlide === 2 ? 'active' : ''
                    }`}
                    onClick={() => handleSupplierSlide(2)}
                  >
                    3
                  </div>
                  <div className='use-text'>
                    구독자가 생긴다면
                    <br />
                    수익이 창출돼요
                  </div>
                </div>
                <div className='use-content-section'>
                  <div
                    className={`use-stage ${
                      currentSupplierSlide === 3 ? 'active' : ''
                    }`}
                    onClick={() => handleSupplierSlide(3)}
                  >
                    4
                  </div>
                  <div className='use-text'>
                    수익률이 높다면
                    <br />더 많은 구독자가 생길거에요
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 연혁 */}
        <div ref={section3Ref} className='history-background'>
          <div className='history-container'>
            <div className='history-index'>Our History</div>
            {/* <div className="history-title">리틀버핏이 걸어온 길</div> */}
            <div className='history-align-container'>
              <div className='history-content-container'>
                <div className='history-section-container'>
                  <div className='history-year'>2024년</div>
                  <div className='history-text-container'>
                    <div className='history-content'>
                      <div className='history-season'>3Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          도전 K-스타트업 2024 본선 진출 (특허청 지식재산리그)
                        </div>
                        <div className='history-text'>
                          2024 컴업스타즈 루키리그 선정
                        </div>
                        <div className='history-text'>
                          2024 코리아핀테크위크 전시부스 참여
                        </div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>2Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          신용보증기금 Start-up Nest 15기 선정
                        </div>
                        <div className='history-text'>
                          한국핀테크지원센터 '디지털 금융기술 내재화 사업’ 선정
                        </div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>1Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          '리틀버핏' 앱 런칭 (Android / iOS)
                        </div>
                        <div className='history-text'>
                          금융위원회 금융규제 샌드박스 간담회 발표
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='horizontal-line' />
                <div className='history-section-container'>
                  <div className='history-year'>2023년</div>
                  <div className='history-text-container'>
                    <div className='history-content'>
                      <div className='history-season'>4Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          서울핀테크위크 핀투데이데모데이 발표
                        </div>
                        <div className='history-text'>
                          신용보증기금 보증서 발급
                        </div>
                        <div className='history-text'>특허권 1건 등록</div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>3Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          은평스타트업리그 대상 수상
                        </div>
                        <div className='history-text'>
                          코스콤 토큰증권매칭데이 발표
                        </div>
                        <div className='history-text'>특허권 1건 등록</div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>2Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          Seed 투자유치
                          (소풍벤처스/더벤처스/넥스트유니콘투자조합)
                        </div>
                        <div className='history-text'>
                          한국경영정보학회 춘계학술대회 발표
                        </div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>1Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          금융감독원 유사투자자문업 신고/등록
                        </div>
                        <div className='history-text'>
                          국민대학교 창업경진대회 수상
                        </div>
                        <div className='history-text'>
                          제2서울핀테크랩 입주기업 선정
                        </div>
                        <div className='history-text'>특허권 1건 등록</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='horizontal-line' />
                <div className='history-section-container'>
                  <div className='history-year'>2022년</div>
                  <div className='history-text-container'>
                    <div className='history-content'>
                      <div className='history-season'>4Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          중소벤처기업진흥공단 청년창업사관학교 양호등급 졸업
                        </div>
                        <div className='history-text'>
                          벤처기업 인증 (혁신성장유형)
                        </div>
                        <div className='history-text'>디자인권 1건 취득</div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>3Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          금융위원회 혁신금융서비스 2차 신청
                        </div>
                        <div className='history-text'>
                          실계좌 기반 포트폴리오 커뮤니티 '리틀버핏' 시제품 개발
                        </div>
                        <div className='history-text'>
                          국민대기술지주 콘택트데모데이 발표
                        </div>
                        <div className='history-text'>기업부설연구소 설립</div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>2Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          한국경영정보학회 춘계학술대회 발표
                        </div>
                        <div className='history-text'>
                          중소기업 R&D역량제고사업 선정
                        </div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>1Q</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          한국핀테크지원센터 예비창업패키지 우수등급 졸업
                        </div>
                        <div className='history-text'>
                          지식경영연구 23권 1호 학술논문 게재
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='horizontal-line' />
                <div className='history-section-container'>
                  <div className='history-year'>2021년</div>
                  <div className='history-text-container'>
                    <div className='history-content'>
                      <div className='history-season'>12월</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          금융위원회 혁신금융서비스 1차 신청
                        </div>
                        <div className='history-text'>
                          국민대학교 산학협력 공모전 수상, 산학협력 MOU 체결
                        </div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>11월</div>
                      <div className='history-text-container'>
                        <div className='history-text'>등록 특허 2건 취득</div>
                        <div className='history-text'>
                          한국지식경영학회 추계학술대회 발표
                        </div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>10월</div>
                      <div className='history-text-container'>
                        <div className='history-text'>
                          기술보증기금 예비창업자사전보증 승인
                        </div>
                        <div className='history-text'>
                          NICE평가정보 기술평가 우수기업 인증
                        </div>
                      </div>
                    </div>
                    <div className='history-content'>
                      <div className='history-season'>9월</div>
                      <div className='history-text-container'>
                        <div className='history-text'>법인 설립</div>
                        <div className='history-text'>
                          지식경영연구 22권 3호 학술논문 게재
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 파트너 */}
        <div className='partner-container'>
          <div className='partner-index'>Our Partners</div>
          <div className='image-container'>
            <img
              className='partner-image'
              src='./images/partners/1.png'
              alt='partner1'
            />
            <img
              className='partner-image'
              src='./images/partners/2.png'
              alt='partner2'
            />
            <img
              className='partner-image'
              src='./images/partners/3.png'
              alt='partner3'
            />
            <img
              className='partner-image'
              src='./images/partners/4.png'
              alt='partner4'
            />
            <img
              className='partner-image'
              src='./images/partners/5.png'
              alt='partner5'
            />
            <img
              className='partner-image'
              src='./images/partners/6.png'
              alt='partner6'
            />
            <img
              className='partner-image'
              src='./images/partners/7.png'
              alt='partner7'
            />
            <img
              className='partner-image'
              src='./images/partners/8.png'
              alt='partner8'
            />
            <img
              className='partner-image'
              src='./images/partners/9.png'
              alt='partner9'
            />
            <img
              className='partner-image'
              src='./images/partners/10.png'
              alt='partner10'
            />
            <img
              className='partner-image'
              src='./images/partners/11.png'
              alt='partner11'
            />
            <img
              className='partner-image'
              src='./images/partners/12.png'
              alt='partner12'
            />
          </div>
        </div>
        {/* 뉴스 */}
        <div ref={section4Ref} className='news-background'>
          <div className='news-container'>
            <div className='news-index'>News</div>
            <div className='news-section'>
              <div className='news-title'>
                <div className='news-info-container'>
                  {/* <div className="news-date">2023년 10월 12일</div> */}
                  <div className='news-company'>한경잡앤조이</div>
                </div>
                <a
                  href='https://magazine.hankyung.com/job-joy/article/202409097946d'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='news-link'
                >
                  <div className='news-link-title'>
                    IFA를 위한 책임형 토큰증권 ‘리틀버핏’ 서비스 개발한
                    ‘프랙탈에프엔’
                  </div>
                  <div className='news-arrow-icon'>&gt;</div>
                </a>
              </div>
              <div className='horizontal-line' />
              <div className='news-title'>
                <div className='news-info-container'>
                  {/* <div className="news-date">2023년 10월 12일</div> */}
                  <div className='news-company'>벤처스퀘어</div>
                </div>
                <a
                  href='https://www.venturesquare.net/939003'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='news-link'
                >
                  <div className='news-link-title'>
                    서울핀테크랩-제2서울핀테크랩, ‘코리아 핀테크 위크 2024’
                    마무리
                  </div>
                  <div className='news-arrow-icon'>&gt;</div>
                </a>
              </div>
              <div className='horizontal-line' />
              <div className='news-title'>
                <div className='news-info-container'>
                  {/* <div className="news-date">2023년 10월 12일</div> */}
                  <div className='news-company'>에너지경제</div>
                </div>
                <a
                  href='https://www.ekn.kr/web/view.php?key=20240416021026824'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='news-link'
                >
                  <div className='news-link-title'>
                    한국핀테크지원센터, ‘2024년 디지털 금융기술 내재화 사업’
                    수혜기업 12개사 선정
                  </div>
                  <div className='news-arrow-icon'>&gt;</div>
                </a>
              </div>
              <div className='horizontal-line' />
              <div className='news-title'>
                <div className='news-info-container'>
                  {/* <div className="news-date">2023년 10월 12일</div> */}
                  <div className='news-company'>서울파이낸스</div>
                </div>
                <a
                  href='https://www.seoulfn.com/news/articleView.html?idxno=512586'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='news-link'
                >
                  <div className='news-link-title'>
                    프랙탈에프엔, 금융위의 제2회 '찾아가는 금융규제 샌드박스'
                    참석
                  </div>
                  <div className='news-arrow-icon'>&gt;</div>
                </a>
              </div>
              <div className='horizontal-line' />
              <div className='news-title'>
                <div className='news-info-container'>
                  {/* <div className="news-date">2023년 10월 12일</div> */}
                  <div className='news-company'>한경잡앤조이</div>
                </div>
                <a
                  href='https://magazine.hankyung.com/job-joy/article/202310123832d'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='news-link'
                >
                  <div className='news-link-title'>
                    IFA를 위한 책임형 토큰증권 서비스를 개발하는 스타트업
                    ‘프랙탈에프엔’
                  </div>
                  <div className='news-arrow-icon'>&gt;</div>
                </a>
              </div>
              <div className='horizontal-line' />
              <div className='news-title'>
                <div className='news-info-container'>
                  {/* <div className="news-date">2023년 10월 12일</div> */}
                  <div className='news-company'>조선일보</div>
                </div>
                <a
                  href='https://www.chosun.com/economy/smb-venture/2023/10/12/QRF6JQJ5C5EULNV366E5JQ47WU/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='news-link'
                >
                  <div className='news-link-title'>
                    프랙탈에프엔, 주식 고수의 포트폴리오를 기술로 확인할 수
                    있다면?
                  </div>
                  <div className='news-arrow-icon'>&gt;</div>
                </a>
              </div>
              <div className='horizontal-line' />
              <div className='news-title'>
                <div className='news-info-container'>
                  {/* <div className="news-date">2023년 7월 21일</div> */}
                  <div className='news-company'>데일리경제</div>
                </div>
                <a
                  href='http://www.kdpress.co.kr/news/articleView.html?idxno=121313'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='news-link'
                >
                  <div className='news-link-title'>
                    국민대 학생창업 기업 ㈜프랙탈에프엔 시드투자 유치
                  </div>
                  <div className='news-arrow-icon'>&gt;</div>
                </a>
              </div>
              <div className='horizontal-line' />
              <div className='news-title'>
                <div className='news-info-container'>
                  {/* <div className="news-date">2023년 2월 23일</div> */}
                  <div className='news-company'>시선뉴스</div>
                </div>
                <a
                  href='https://www.sisunnews.co.kr/news/articleView.html?idxno=179159'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='news-link'
                >
                  <div className='news-link-title'>
                    국민대, ‘2023 국민대학교 국민*창업경진대회’ 마무리
                  </div>
                  <div className='news-arrow-icon'>&gt;</div>
                </a>
              </div>
              <div className='horizontal-line' />
              <div className='news-title'>
                <div className='news-info-container'>
                  {/* <div className="news-date">2022년 10월 5일</div> */}
                  <div className='news-company'>이데일리</div>
                </div>
                <a
                  href='https://www.edaily.co.kr/news/read?newsId=03473526632489968&mediaCodeNo=257&OutLnkChk=Y'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='news-link'
                >
                  <div className='news-link-title'>
                    국민대, 대학 창업기업 위한 '콘(K-ON)택트 데모데이' 성료
                  </div>
                  <div className='news-arrow-icon'>&gt;</div>
                </a>
              </div>
              <div className='horizontal-line' />
            </div>
          </div>
        </div>
        {/* 뉴스레터 */}
        <div ref={section5Ref} className='newsletter-container'>
          <div className='newsletter-index'>Newsletter</div>
          <div className='newsletter-title'>
            투자자를 위한 국내·해외 주식 뉴스레터, 매일 무료로 받아보세요.
          </div>
          <SubscriptionForm />
        </div>
        {/* 문의하기*/}
        {/* <div className="inquiry-container">
        <div className="inquiry-index">Inquiry</div>
        <div className="inquiry-title">서비스 관련 문의를 남겨주세요.</div>
        <ContactForm />
      </div> */}
        {/* 하단 이미지 */}
        <div className='bottom-background-container'>
          <div className='bottom-title-container'>
            <div className='bottom-title-top'>
              책임형 투자 문화를 만들어갑니다
            </div>
            <div className='bottom-title-bottom'>리틀버핏 앱 설치하기</div>
            <div className='download-container'>
              {/* <div onClick={showIosModal} className="ios-link">
                <img className='download-icon' src="./images/appstore.png" alt="apple download icon" />
              </div> */}
              <a
                href='https://apps.apple.com/kr/app/id6477274306'
                target='_blank'
                rel='noopener noreferrer'
                className='android-link'
              >
                <img
                  className='download-icon'
                  src='./images/appstore.png'
                  alt='apple download icon'
                />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.fractalfn.littlebuffett'
                target='_blank'
                rel='noopener noreferrer'
                className='android-link'
              >
                <img
                  className='download-icon'
                  src='./images/googleplay.png'
                  alt='google download icon'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <IosLaunchModal isOpen={isIosModalOpen} onClose={hideIosModal} />
    </>
  );
}
